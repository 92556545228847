

import React from 'react';
import { Link } from 'react-router-dom';
import './CareerNavbar.css'; // Import the CSS file for styling
import logo from './logo.png';

const CareerNavbar = () => {
  return (
    <div className="career-navbar n-wrapper">
      <div className="container">
        <div className="n-container">
          <Link to="/" className="n-logo">
          {/* <h2>Mafkin Robotics</h2> */}
            {/* <img className="logo-image" src="./logo.svg" alt="Logo" /> */}
            <img className="logo-image" src={logo} alt="Logo" />
          </Link>
          <div className="n-right">
            <Link to="/" className="home-button">
              Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerNavbar;
