import React from 'react';
import './JobItem.css';

const JobItem = ({ title, description, location, type, applyLink }) => {
  // Function to handle the Apply button click
  const handleApplyClick = () => {
    window.location.href = 'https://forms.gle/wNzgrdkcgs4CUv3g6'; // Redirect to the applyLink URL
  };

  return (
    <div className="job-item">
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="job-details">
        <span>Location: {location}</span>
        <span>Type: {type}</span>
      </div>
      <button onClick={handleApplyClick} className="apply-button">Apply</button>
    </div>
  );
};

export default JobItem;

// import React from 'react';
// import JobItem from './JobItem';
// import jobs from './jobs.json';
// import './JobList.css';

// const JobList = ({ category }) => {
//   const filteredJobs = category === 'All' ? jobs : jobs.filter(job => job.category === category);

//   return (
//     <div className="job-list">
//       {filteredJobs.map((job, index) => (
//         <JobItem
//           key={index}
//           title={job.title}
//           description={job.description}
//           location={job.location}
//           type={job.type}
//           applyLink={'job.applyLink'} // Pass the applyLink prop
//         />
//       ))}
//     </div>
//   );
// };

// export default JobList;
