import React, { useState } from "react";
import "./Timeline.css";
import { fadeIn, tagVaraints } from "../../utils/animation";
import { motion } from "framer-motion";
import Modal from "react-modal";

// Sample data for the timeline
const milestones = [
  {
    date: "July 2023",
    title: "Foundation",
    // description:
    //   "Founded Mafkin Robotics in July 2023 to innovate maritime industry with automated ship hull cleaning.",
    detail:
      "Founded Mafkin Robotics in July 2023 to innovate maritime industry with automated ship hull cleaning."
  },

  {
    date: "September 2023",
    title: "Government Grant Approval",
    // description:
    //   "Secured government funding in September 2023 to advance fuel-efficient ship maintenance technologies at Mafkin Robotics.",
    detail:
      "Secured government funding in September 2023 to advance fuel-efficient ship maintenance technologies at Mafkin Robotics.",
  },{
    date: "July 2024",
    title: "Completion of 1st PoC product",
    // description:
    //   "Completed the first proof-of-concept for automated ship hull cleaning in July 2024, advancing greener maritime solutions.",
    detail:
      "Completed the first proof-of-concept for automated ship hull cleaning in July 2024, advancing greener maritime solutions.",
  },
  // {
  //   date: "January 2024",
  //   title: "Launch of First Product",
  //   // description:
  //   //   "Revealing Makfin Robotics' inaugural product in January 2024, a pioneering solution simplifying and automating ship hull cleaning, signaling progress toward a greener and more efficient maritime sector.",
  //   detail:
  //     "Revealing Makfin Robotics' inaugural product in January 2024, a pioneering solution simplifying and automating ship hull cleaning, signaling progress toward a greener and more efficient maritime sector.",
  // },
  {
    date: "October 2024",
    title: "Testing and trials completion",
    // description:
    //   "Completed testing and trials of the first product in October 2024, ensuring its efficiency and effectiveness in real-world maritime applications.",
    detail:
      "Completed testing and trials of the first product in October 2024, ensuring its efficiency and effectiveness in real-world maritime applications.",
  },
  {
    date: "January 2025",
    title: "Launch of First Product",
    // description:
    //   "Revealing Makfin Robotics' inaugural product in January 2024, a pioneering solution simplifying and automating ship hull cleaning, signaling progress toward a greener and more efficient maritime",
    detail:
      "Revealing Makfin Robotics' inaugural product in January 2024, a pioneering solution simplifying and automating ship hull cleaning, signaling progress toward a greener and more efficient maritime",
  },
];

const Timeline = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent({});
  };

  return (
    <section className="timeline-section">
      <motion.span
        className="m-heading"
        variants={tagVaraints}
        initial="offscreen"
        whileInView={"onscreen"}
      >
        <h2>Mafkin Milestones</h2>
      </motion.span>
      <div className="timeline-items">
        {milestones.map((milestone, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-dot"></div>
            <div className="timeline-date">{milestone.date}</div>
            <motion.div
              variants={fadeIn(index % 2 === 0 ? "right" : "left", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="timeline-content"
            >
              <h3>{milestone.title}</h3>
              <p>{milestone.description}</p>
              <button className="learn-more-btn" onClick={() => openModal(milestone)}>Learn More</button>
            </motion.div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Milestone Detail"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{modalContent.title}</h2>
        <p>{modalContent.detail}</p>
        <button onClick={closeModal} className="modal-close-btn">Close</button>
      </Modal>
    </section>
  );
};

export default Timeline;
