import React, { useState, useEffect } from "react";
import "./Email.css";
import { motion } from "framer-motion";
import { LuMail } from "react-icons/lu";
import { containerVariants } from "../../utils/animation";
import { Link } from "react-scroll";

const Email = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Check screen width on component mount and window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <motion.div
      initial={{
        width: ".5rem",
        borderRadius: "100%",
      }}
      whileInView={{
        width: "70%",
        borderRadius: "999px",
        transition: {
          type: "easeOut",
          duration: 1,
        },
      }}
      className="emailbox"
    >
      <motion.div
        variants={containerVariants(0.6)}
        initial="offscreen"
        whileInView={"onscreen"}
        viewport={{
          once: true,
        }}
      >
        <LuMail size={30} color="grey" />
      </motion.div>

      {/* Conditionally render the email input */}
      {!isMobile && (
        <motion.input
          variants={containerVariants(0.7)}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{
            once: true,
          }}
          type="email"
          placeholder="Enter Email"
        />
      )}

      {/* Conditionally render the button */}
      {!isMobile && (
        <motion.div
          variants={containerVariants(0.9)}
          initial="offscreen"
          whileInView={"onscreen"}
          viewport={{
            once: true,
          }}
          className="mail-quote"
        >
          <Link to="contact-section" spy={true} smooth={true} offset={-65}>
            Book Appointment
          </Link>
        </motion.div>
      )}
    </motion.div>
  );
};

export default Email;

// import React from "react";
// import "./Email.css";
// import { motion } from "framer-motion";
// import { LuMail } from "react-icons/lu";
// import { containerVariants } from "../../utils/animation";
// import { Link } from "react-scroll";
// const Email = () => {
//   return (
//     <motion.div
//       initial={{
//         width: ".5rem",
//         borderRadius: "100%",
//       }}
//       whileInView={{
//         width: "70%",
//         borderRadius: "999px",
//         transition: {
//           type: "easeOut",
//           duration: 1,
//         },
//       }}
//       className="emailbox"
//     >
//       <motion.div
//         variants={containerVariants(0.6)}
//         initial="offscreen"
//         whileInView={"onscreen"}
//         viewport={{
//           once: true,
//         }}
//       >
//         <LuMail size={30} color="grey" />
//       </motion.div>

//       <motion.input
//         variants={containerVariants(0.7)}
//         initial="offscreen"
//         whileInView={"onscreen"}
//         viewport={{
//           once: true,
//         }}
//         type="email"
//         placeholder="Enter Email"
//       />

      

//       <motion.div
//         variants={containerVariants(0.9)}
//         initial="offscreen"
//         whileInView={"onscreen"}
//         viewport={{
//           once: true,
//         }}
//         className="mail-quote"
//       >
//         <Link to="contact-section" spy={true} smooth={true} offset={-65}>Book Appointment</Link>
//       </motion.div>
      
     
//     </motion.div>
//   );
// };

// export default Email;
