import React, { useState, useEffect } from 'react';
import './career.css';
import JobList from './JobList';
import CareerNavbar from './CareerNavbar'; // Import the CareerNavbar component
import electric from './electrical-energy.png';
import mechanical from './mech.png';
import develop from './app-development.png';
import designer from './graphic-designer.png';
import market from './bullhorn.png';
import allview from './viewall.png';

const Career = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isVisible, setIsVisible] = useState(false);

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const handleScroll = () => {
      const careerSection = document.querySelector('.career-section');
      if (!careerSection) {
        console.error('Career section element not found');
        return;
      }

      const windowHeight = window.innerHeight;
      const sectionTop = careerSection.getBoundingClientRect().top;

      if (sectionTop < windowHeight * 0.75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Run the check once on component mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <CareerNavbar /> {/* Add CareerNavbar at the top */}
      <section className={`career-section ${isVisible ? 'visible' : ''}`}>
        <div className="career-bg">
          <h1>Career</h1>
          <div className="line">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="career-body">
          <div className="career-content">
            <h2>Be part of our mission</h2>
            <p>We're looking for passionate people to join us on our mission. We value flat hierarchies, clear communication, and full ownership and responsibility.</p>
            <div className="button-container">
              <div className="cardsB allview" onClick={() => handleFilterClick('All')}>
                <img src={allview} alt="View All" />
                <h3>View All</h3>
                <p>See all available categories.</p>
              </div>
              <div className="cardsB electrical" onClick={() => handleFilterClick('Mechanical')}>
                <img src={mechanical} alt="Mechanical" />
                <h3>Mechanical</h3>
                <p>Explore mechanical jobs.</p>
              </div>
              <div className="cardsB electrical" onClick={() => handleFilterClick('Electrical')}>
                <img src={electric} alt="Electrical" />
                <h3>Electrical</h3>
                <p>Explore electrical jobs.</p>
              </div>
              <div className="cardsB development" onClick={() => handleFilterClick('Development')}>
                <img src={develop} alt="Development" />
                <h3>Development</h3>
                <p>Find development opportunities.</p>
              </div>
              <div className="cardsB design" onClick={() => handleFilterClick('Design')}>
                <img src={designer} alt="Design" />
                <h3>Design</h3>
                <p>Discover design roles.</p>
              </div>
              <div className="cardsB marketing" onClick={() => handleFilterClick('Marketing')}>
                <img src={market} alt="Marketing" />
                <h3>Marketing</h3>
                <p>Check out marketing positions.</p>
              </div>
            </div>
            <JobList category={selectedCategory} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Career;

// import React, { useState, useEffect } from 'react';
// import './career.css';
// import JobList from './JobList';
// import CareerNavbar from './CareerNavbar'; // Import the CareerNavbar component
// import electric from './electrical-energy.png';
// import mechanical from'./mech.png';
// import develop from './app-development.png';
// import designer from './graphic-designer.png'
// import market from './bullhorn.png';
// import allview from './viewall.png'
// const Career = () => {
//   const [selectedCategory, setSelectedCategory] = useState('All');
//   const [isVisible, setIsVisible] = useState(false);

//   const handleFilterClick = (category) => {
//     setSelectedCategory(category);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const careerSection = document.querySelector('.career-section');
//       if (!careerSection) {
//         console.error('Career section element not found');
//         return;
//       }

//       const windowHeight = window.innerHeight;
//       const sectionTop = careerSection.getBoundingClientRect().top;

//       if (sectionTop < windowHeight * 0.75) {
//         setIsVisible(true);
//       } else {
//         setIsVisible(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     handleScroll(); // Run the check once on component mount

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <div>
//       <CareerNavbar /> {/* Add CareerNavbar at the top */}
//       <section className={`career-section ${isVisible ? 'visible' : ''}`}>
//         <div className="career-bg">
//           <h1>Career</h1>
//           <div className="line">
//             <div></div>
//             <div></div>
//             <div></div>
//           </div>
//         </div>
//         <div className="career-body">
//           <div className="career-content">
//             <h2>Be part of our mission</h2>
//             <p>We're looking for passionate people to join us on our mission. We value flat hierarchies, clear communication, and full ownership and responsibility.</p>
//             <div className="button-container">
//               <div className="cardsB allview" onClick={() => handleFilterClick('All')}>
//                 <img src={allview} alt="View All" />
//                 <h3>View All</h3>
//                 <p>See all available categories.</p>
//               </div>
//               <div className="cardsB electrical" onClick={() => handleFilterClick('Mechanical')}>
//                 <img src={mechanical} alt="Mechanical" />
//                 <h3>Mechanical</h3>
//                 <p>Explore mechanical jobs.</p>
//               </div>
//               <div className="cardsB electrical" onClick={() => handleFilterClick('Electrical')}>
//                 <img src={electric} alt="Electrical" />
//                 <h3>Electrical</h3>
//                 <p>Explore electrical jobs.</p>
//               </div>
//               <div className="cardsB development" onClick={() => handleFilterClick('Development')}>
//                 <img src={develop} alt="Development" />
//                 <h3>Development</h3>
//                 <p>Find development opportunities.</p>
//               </div>
//               <div className="cardsB design" onClick={() => handleFilterClick('Design')}>
//                 <img src={designer} alt="Design" />
//                 <h3>Design</h3>
//                 <p>Discover design roles.</p>
//               </div>
//               <div className="cardsB marketing" onClick={() => handleFilterClick('Marketing')}>
//                 <img src={market} alt="Marketing" />
//                 <h3>Marketing</h3>
//                 <p>Check out marketing positions.</p>
//               </div>
//             </div>
//             <JobList category={selectedCategory} />
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };
// export default Career;
